// FONT FACE --INTER
@font-face {
  font-family: Inter;
  font-weight: 300;
  src: url("../fonts/Inter/static/Inter-Light.ttf");
}
@font-face {
  font-family: Inter;
  font-weight: 400;
  src: url("../fonts/Inter/static/Inter-Regular.ttf");
}
@font-face {
  font-family: Inter;
  font-weight: 500;
  src: url("../fonts/Inter/static/Inter-Medium.ttf");
}
@font-face {
  font-family: Inter;
  font-weight: 600;
  src: url("../fonts/Inter/static/Inter-SemiBold.ttf");
}
@font-face {
  font-family: Inter;
  font-weight: 700;
  src: url("../fonts/Inter/static/Inter-Bold.ttf");
}

// POPPINS
@font-face {
  font-family: Poppins;
  font-weight: 300;
  src: url("../fonts/Poppins/Poppins-Light.ttf");
}
@font-face {
  font-family: Poppins;
  font-weight: 400;
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: Poppins;
  font-weight: 500;
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
}
@font-face {
  font-family: Poppins;
  font-weight: 600;
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: Poppins;
  font-weight: 700;
  src: url("../fonts/Poppins/Poppins-Bold.ttf");
}

//
// FONT FACE --MerriweatherSans
@font-face {
  font-family: MerriweatherSans;
  font-weight: 300;
  src: url("../fonts/Merriweather_Sans/static/MerriweatherSans-Light.ttf");
}
@font-face {
  font-family: MerriweatherSans;
  font-weight: 400;
  src: url("../fonts/Merriweather_Sans/static/MerriweatherSans-Regular.ttf");
}
@font-face {
  font-family: MerriweatherSans;
  font-weight: 500;
  src: url("../fonts/Merriweather_Sans/static/MerriweatherSans-Medium.ttf");
}
@font-face {
  font-family: MerriweatherSans;
  font-weight: 600;
  src: url("../fonts/Merriweather_Sans/static/MerriweatherSans-SemiBold.ttf");
}
@font-face {
  font-family: MerriweatherSans;
  font-weight: 700;
  src: url("../fonts/Merriweather_Sans/static/MerriweatherSans-Bold.ttf");
}
//
// FONT FACE --Kodchasan
@font-face {
  font-family: Kodchasan;
  font-weight: 300;
  src: url("../fonts/Kodchasan/Kodchasan-Light.ttf");
}
@font-face {
  font-family: Kodchasan;
  font-weight: 400;
  src: url("../fonts/Kodchasan/Kodchasan-Regular.ttf");
}
@font-face {
  font-family: Kodchasan;
  font-weight: 500;
  src: url("../fonts/Kodchasan/Kodchasan-Medium.ttf");
}
@font-face {
  font-family: Kodchasan;
  font-weight: 600;
  src: url("../fonts/Kodchasan/Kodchasan-SemiBold.ttf");
}
@font-face {
  font-family: Kodchasan;
  font-weight: 700;
  src: url("../fonts/Kodchasan/Kodchasan-Bold.ttf");
}

// FONT FACE --BeVietnam
@font-face {
  font-family: BeVietnam;
  font-weight: 300;
  src: url("../fonts/Be_Vietnam/BeVietnam-Light.ttf");
}
@font-face {
  font-family: BeVietnam;
  font-weight: 400;
  src: url("../fonts/Be_Vietnam/BeVietnam-Regular.ttf");
}
@font-face {
  font-family: BeVietnam;
  font-weight: 500;
  src: url("../fonts/Be_Vietnam/BeVietnam-Medium.ttf");
}
@font-face {
  font-family: BeVietnam;
  font-weight: 600;
  src: url("../fonts/Be_Vietnam/BeVietnam-SemiBold.ttf");
}
@font-face {
  font-family: BeVietnam;
  font-weight: 700;
  src: url("../fonts/Be_Vietnam/BeVietnam-Bold.ttf");
}

// ----------- IranSans V4 

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/IranSans-V4/fonts/eot/IRANSansWeb_Bold.eot');
  src: url('../fonts/IranSans-V4/fonts/eot/IRANSansWeb_Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-8 */
    url('../fonts/IranSans-V4/fonts/woff2/IRANSansWeb_Bold.woff2') format('woff2'),
    /* FF39+,Chrome36+, Opera24+*/
    url('../fonts/IranSans-V4/fonts/woff/IRANSansWeb_Bold.woff') format('woff'),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('../fonts/IranSans-V4/fonts/ttf/IRANSansWeb_Bold.ttf') format('truetype');
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/IranSans-V4/fonts/eot/IRANSansWeb_Medium.eot');
  src: url('../fonts/IranSans-V4/fonts/eot/IRANSansWeb_Medium.eot?#iefix') format('embedded-opentype'),
    /* IE6-8 */
    url('../fonts/IranSans-V4/fonts/woff2/IRANSansWeb_Medium.woff2') format('woff2'),
    /* FF39+,Chrome36+, Opera24+*/
    url('../fonts/IranSans-V4/fonts/woff/IRANSansWeb_Medium.woff') format('woff'),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('../fonts/IranSans-V4/fonts/ttf/IRANSansWeb_Medium.ttf') format('truetype');
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/IranSans-V4/fonts/eot/IRANSansWeb_Light.eot');
  src: url('../fonts/IranSans-V4/fonts/eot/IRANSansWeb_Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-8 */
    url('../fonts/IranSans-V4/fonts/woff2/IRANSansWeb_Light.woff2') format('woff2'),
    /* FF39+,Chrome36+, Opera24+*/
    url('../fonts/IranSans-V4/fonts/woff/IRANSansWeb_Light.woff') format('woff'),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('../fonts/IranSans-V4/fonts/ttf/IRANSansWeb_Light.ttf') format('truetype');
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/IranSans-V4/fonts/eot/IRANSansWeb_UltraLight.eot');
  src: url('../fonts/IranSans-V4/fonts/eot/IRANSansWeb_UltraLight.eot?#iefix') format('embedded-opentype'),
    /* IE6-8 */
    url('../fonts/IranSans-V4/fonts/woff2/IRANSansWeb_UltraLight.woff2') format('woff2'),
    /* FF39+,Chrome36+, Opera24+*/
    url('../fonts/IranSans-V4/fonts/woff/IRANSansWeb_UltraLight.woff') format('woff'),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('../fonts/IranSans-V4/fonts/ttf/IRANSansWeb_UltraLight.ttf') format('truetype');
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/IranSans-V4/fonts/eot/IRANSansWeb.eot');
  src: url('../fonts/IranSans-V4/fonts/eot/IRANSansWeb.eot?#iefix') format('embedded-opentype'),
    /* IE6-8 */
    url('../fonts/IranSans-V4/fonts/woff2/IRANSansWeb.woff2') format('woff2'),
    /* FF39+,Chrome36+, Opera24+*/
    url('../fonts/IranSans-V4/fonts/woff/IRANSansWeb.woff') format('woff'),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('../fonts/IranSans-V4/fonts/ttf/IRANSansWeb.ttf') format('truetype');
}

//
html body {
  @apply font-body antialiased;
}

h1,
h2,
h3,
h4 {
  @apply font-display;
}

:root {
  --font-display: IRANSans;
  --font-body: IRANSans;
}

.theme-animals {
  --font-display: Inter;
  --font-body: Inter;
}

.theme-astronomy {
  --font-display: BeVietnam;
  --font-body: BeVietnam;
}
